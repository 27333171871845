:root {
	--primary: #03243c;
	--mdc-theme-primary: #03243c;
	--primary-nasa: #03243c;
	--primary-green: #6ea424;
	--primary-color: #03243c;
	--primary-btn: #6ea424;
}

.header {
	box-shadow: 0 1px 15px 1px rgb(81 77 92 / 50%);

	.img-wrapper {
		text-align: center;
		padding: 15px 0;

		img {
			max-width: 250px;
		}
	}
}

.footer {
	padding: 0 0 15px;
	box-shadow: -2px -8px 41px -14px rgb(202 210 222 / 61%);

	.container {
		padding: 0;
		max-width: 100%;

		.row {
			margin: 0;

			.col-12 {
				padding: 0;
			}
		}
	}

	.footer-content {
		padding-bottom: 0;
		background-color: transparent;

		.footer-title {
			padding: 20px;
		}
	}
}

.product-section-box {

	&.manage-offer {

		.pricing-content {
			.price-column-container {
				.price-table-info {
					.price-table-head {
						background: var(--primary-nasa) !important;

						&:after {
							border-top: 12px solid var(--primary-nasa) !important;
						}
					}
				}
			}
		}
	}
}
